<template>
  <div>
    <p class="text-2xl mb-6">Dashboard Content to Commerce</p>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" md="12">
        <affiliations></affiliations>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/c2c/dashboard/filters/Filters";
import Affiliations from "@/components/c2c/dashboard/tables/Affiliations";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Index",
  components: {
    Affiliations,
    Filters,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {};
  },
};
</script>
