<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="3">
        <date-filter store="c2c"></date-filter>
      </v-col>
      <v-col cols="3">
        <site-groups-filter store="c2c" />
      </v-col>
      <v-col cols="6">
        <sites-filter store="c2c" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";

export default {
  name: "Filters",
  components: {
    DateFilter,
    SitesFilter,
    SiteGroupsFilter,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
