<template>
  <v-card v-if="Object.keys(stats).length > 0">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <span class="font-weight-bold">Affiliations : </span>{{ stats.total }}
        </v-col>
        <v-col cols="6">
          <span class="font-weight-bold">Clics : </span>{{ stats.nbClicks }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span class="font-weight-bold">Transacs : </span
          >{{ stats.nbTransacs }}
        </v-col>
        <v-col cols="6">
          <span class="font-weight-bold">Comm : </span>{{ stats.commission }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span class="font-weight-bold">Sessions : </span>{{ stats.sessions }}
        </v-col>
        <v-col cols="6">
          <span class="font-weight-bold">Vues : </span>{{ stats.views }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatCurrency, formatNumber } from "@/utils/formatting";

export default {
  name: "TableStats",
  props: {
    data: {
      type: Object,
      default: () => ({
        total: 0,
        sessions: 0,
        views: 0,
        nbClicks: 0,
        nbTransacs: 0,
        commission: 0,
      }),
    },
  },
  data() {
    return {
      stats: {},
    };
  },
  methods: {
    formatStats() {
      const { stats, total } = this.data;
      if (stats === undefined || total === undefined) {
        return {};
      }

      return {
        nbClicks: formatNumber(stats.nb_clicks),
        nbTransacs: formatNumber(
          stats.transac_confirmed + stats.transac_pending
        ),
        commission: formatCurrency(
          stats.commission_confirmed + stats.commission_pending
        ),
        views: formatNumber(stats.views),
        sessions: formatNumber(stats.sessions),
        total: formatNumber(total),
      };
    },
  },
  watch: {
    data() {
      this.stats = this.formatStats();
    },
  },
};
</script>
